import Vue from 'vue';
import Vuex from 'vuex';
import user from '@/store/user.js';
Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		user,
	},
	state: {
		loading: 0,
		menuAtivado: false,
		modal: '',
		alerta: {
			alertaAtivado: false,
			tituloAlerta: '',
			mensagemAlerta: '',
		},
	},
	mutations: {
		SET_ADICIONAR_LOADING(state) {
			state.loading = ++state.loading;
		},
		SET_REMOVER_LOADING(state) {
			state.loading = --state.loading;
		},
		SET_MENU(state, menu) {
			state.menuAtivado = menu;
		},
		SET_MODAL(state, modal) {
			state.modal = modal;
		},
		SET_ALERTA(state, alerta) {
			state.alerta.alertaAtivado = alerta.ativado;
			state.alerta.tituloAlerta = alerta.titulo;
			state.alerta.mensagemAlerta = alerta.mensagem;
		},
	},
});
