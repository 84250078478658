<template>
	<div id="canvas">
		<div class="fs-container">
			<div class="literally"><canvas></canvas></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Canvas',
		mounted() {
			let scriptInicial = document.createElement('script');
			scriptInicial.innerHTML = `
			$(".literally").literallycanvas();
			`;
			document.querySelector('#canvas').appendChild(scriptInicial);
		},
	};
</script>
