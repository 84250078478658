<template>
	<section class="home-container">
		<div v-if="lastUpdated" class="last-updated">
			<p>
				Last updated: <span>{{ lastUpdated }}</span>
			</p>
			<button @click="atualizarLista()">Update</button>
		</div>
		<div v-if="!listas" class="sem-lista-disponivel">
			<h3>No list available</h3>
		</div>
		<div v-else class="lista-container">
			<div
				class="lista-item"
				v-for="(itemLista, key, index) in listas"
				:key="index"
			>
				<h3>{{ itemLista.nomeLista }}</h3>
				<div class="botoes-container">
					<button @click="excluirItem(key)" class="btn-icon">
						<img src="@/assets/img/icon/icon-lixeira.svg" alt="Icon Delete" />
					</button>
					<button
						class="btn-circle btn-play-lista"
						@click="playList(key, itemLista.nomeLista)"
					>
						<span
							><img src="@/assets/img/icon/icon-play.svg" alt="Icon Play"
						/></span>
					</button>
					<button
						@click="editarItem(key, itemLista.nomeLista)"
						class="btn-icon"
					>
						<img src="@/assets/img/icon/icon-editar.svg" alt="Icon Edit" />
					</button>
				</div>
				<h4>{{ itemLista.lista.length }} Kanji</h4>
			</div>
			<div class="lista-item lista-item-add">
				<div class="botoes-container">
					<router-link class="btn-circle btn-criar-lista" to="/create-list"
						><span
							><img src="@/assets/img/icon/icon-add.svg" alt="Icon Add"
						/></span>
					</router-link>
				</div>
			</div>
		</div>
		<router-link
			v-if="!listas"
			class="btn-circle btn-criar-lista"
			to="/create-list"
			><span><img src="@/assets/img/icon/icon-add.svg" alt="Icon Add"/></span>
		</router-link>
	</section>
</template>

<script>
	export default {
		name: 'Home',
		computed: {
			listas() {
				return this.$store.state.user.listas;
			},
			lastUpdated() {
				return this.$store.state.user.lastUpdated;
			},
		},
		methods: {
			atualizarLista() {
				this.$store.commit('SET_MODAL', 'AtualizarLista');
			},
			playList(key, nome) {
				this.$router.push(`/list/${key}/${this.formatarNome(nome)}`);
			},
			editarItem(key, nome) {
				this.$router.push(`/edit-list/${key}/${this.formatarNome(nome)}`);
			},
			excluirItem(key) {
				this.$store.commit('user/SET_KEY_LISTA_EXCLUIR', key);
				this.$store.commit('SET_MODAL', 'ExcluirLista');
			},
			formatarNome(string) {
				const map = {
					'-': ' ',
					a: 'á|à|ã|â|À|Á|Ã|Â',
					c: 'ç|Ç',
				};
				for (let pattern in map) {
					string = string.replace(new RegExp(map[pattern], 'g'), pattern);
				}
				return string.toLowerCase();
			},
		},
	};
</script>
