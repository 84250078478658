<template>
	<div id="login">
		<section>
			<div class="login-img">
				<img src="@/assets/img/logo/logo-fundo.svg" alt="Logo KanjiAnki" />
			</div>
			<form class="form-login" @submit.prevent="login">
				<input
					type="email"
					placeholder="E-mail"
					v-model="form.email"
					required
				/>
				<input
					type="password"
					placeholder="Password"
					v-model="form.password"
					required
				/>
				<button :disabled="$v.form.$invalid">Sign In</button>
			</form>
			<div class="login-links">
				<router-link to="/register">Create an account</router-link>
				<router-link to="/password">Reset password</router-link>
			</div>
			<LinksFooter />
		</section>
	</div>
</template>

<script>
	import firebase from 'firebase';
	import { required, email, minLength } from 'vuelidate/lib/validators';
	import LinksFooter from '@/components/elementos/LinksFooter.vue';
	export default {
		name: 'Login',
		components: {
			LinksFooter,
		},
		data() {
			return {
				form: {
					email: '',
					password: '',
				},
				error: null,
			};
		},
		validations: {
			form: {
				email: { required, email },
				password: { required, minLength: minLength(6) },
			},
		},
		methods: {
			login() {
				this.$store.commit('SET_ADICIONAR_LOADING');
				firebase
					.auth()
					.signInWithEmailAndPassword(this.form.email, this.form.password)
					.then(() => {
						this.$router.push('/');
					})
					.catch((err) => {
						return this.$store.commit('SET_ALERTA', {
							ativado: true,
							titulo: 'Error',
							mensagem: err.message,
						});
					})
					.finally(() => this.$store.commit('SET_REMOVER_LOADING'));
			},
		},
	};
</script>
