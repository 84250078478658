<template>
	<div class="modal-alerta" :class="{ active: alerta.alertaAtivado }">
		<transition mode="out-in" name="fade">
			<div v-if="alerta.alertaAtivado" class="modal-alerta-container">
				<div>
					<h3 v-if="alerta.tituloAlerta">{{ alerta.tituloAlerta }}</h3>
					<p v-html="alerta.mensagemAlerta"></p>
				</div>
				<div>
					<button @click="fecharAlerta">OK</button>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		name: 'MensagemAlerta',
		computed: {
			alerta() {
				return this.$store.state.alerta;
			},
		},
		methods: {
			fecharAlerta() {
				this.$store.commit('SET_ALERTA', {
					ativado: false,
					titulo: '',
					mensagem: '',
				});
			},
		},
	};
</script>

<style lang="scss">
	.modal-alerta {
		position: fixed;
		height: 100vh;
		width: 100%;
		z-index: 200;
		background: $bg-modal;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 20px 50px 20px;
		box-sizing: border-box;
		.modal-alerta-container {
			background: $branco;
			border-radius: 12px;
			width: 100%;
			max-width: 300px;
			box-shadow: 0 30px 30px rgba(#6f6f6f, 0.2);
			> div {
				box-sizing: border-box;
				&:first-of-type {
					padding: 30px 20px 20px 20px;
					border-bottom: 1px solid #ded6ca;
				}
			}
			h3 {
				font-size: 20px;
				font-weight: 700;
				color: $texto;
				text-align: center;
				margin-bottom: 15px;
			}
			p {
				text-align: center;
				widows: 100%;
				max-width: 230px;
				margin: 0 auto;
				font-size: 16px;
				color: $texto;
				line-height: 1.3em;
			}
			button {
				background: transparent;
				font-size: 14px;
				font-weight: 700;
				color: $texto;
				width: 100%;
				border: none;
				height: 60px;
				outline: none;
				cursor: pointer;
				@media (min-width: 701px) {
					transition: 0.3s;
					&:hover {
						color: $azul;
					}
				}
			}
		}
	}
</style>
