export default {
	namespaced: true,
	state: {
		user: {
			loggedIn: false,
			data: null,
		},
		atualizarLista: true,
		keyListaExcluir: null,
		listas: null,
		listasClone: null,
		//
		lastUpdated: null,
	},
	getters: {
		user(state) {
			return state.user;
		},
	},
	mutations: {
		SET_LOGGED_IN(state, value) {
			state.user.loggedIn = value;
		},
		SET_USER(state, data) {
			state.user.data = data;
		},
		SET_ATUALIZAR_LISTA(state, data) {
			state.atualizarLista = data;
		},
		SET_KEY_LISTA_EXCLUIR(state, key) {
			state.keyListaExcluir = key;
		},
		SET_LISTAS(state, data) {
			state.listas = data;
		},
		SET_LISTAS_CLONE(state, data) {
			state.listasClone = data;
		},
		SET_LAST_UPDATED(state, data) {
			state.lastUpdated = data;
		},
	},
	actions: {
		fetchUser({ commit }, user) {
			commit('SET_LOGGED_IN', user !== null);
			if (user) {
				commit('SET_USER', {
					uid: user.uid,
					displayName: user.displayName,
					email: user.email,
				});
			} else {
				commit('SET_USER', null);
			}
		},
	},
};
