<template>
	<div id="main">
		<transition name="fade" mode="out-in">
			<Loading v-if="loading > 0" />
		</transition>
		<transition name="fade" mode="out-in">
			<MensagemAlerta v-if="alerta.alertaAtivado" />
		</transition>
		<router-view />
	</div>
</template>

<script>
	import Loading from '@/components/loading/Loading.vue';
	import MensagemAlerta from '@/components/alerta/MensagemAlerta.vue';
	export default {
		components: {
			Loading,
			MensagemAlerta,
		},
		computed: {
			loading() {
				return this.$store.state.loading;
			},
			alerta() {
				return this.$store.state.alerta;
			},
		},
		watch: {
			loading(val) {
				return val > 0
					? document.querySelector('body').classList.add('block-scroll')
					: document.querySelector('body').classList.remove('block-scroll');
			},
			alerta: {
				deep: true,
				handler: function(val) {
					return val.alertaAtivado
						? document.querySelector('body').classList.add('block-scroll-modal')
						: document
								.querySelector('body')
								.classList.remove('block-scroll-modal');
				},
			},
		},
	};
</script>

<style lang="scss">
	#main {
		min-height: 100vh;
		background: $azul;
		// background: linear-gradient(to bottom, $rosa, $azul);
	}
</style>
