<template>
	<header id="header">
		<div class="header-container">
			<h1 @click="link('/')" class="header-logo">
				<span>Kanji Anki</span>
				<img src="@/assets/img/logo/logo.svg" alt="Logo Kanji Anki" />
			</h1>
			<div class="header-container-links">
				<ul class="header-links" :class="{ active: menuAtivo }">
					<li>Hello, {{ nomeUsuario }}</li>
					<li v-if="linkRouter != '/'" @click="link('/')">Home</li>
					<li v-if="linkRouter != '/create-list'" @click="link('/create-list')">
						New list
					</li>
					<li @click="sair">Log out</li>
				</ul>
				<div v-if="visivel" class="btn-fullscreen">
					<input type="checkbox" id="fullscreen-input" v-model="fullscreen" />
					<label class="fullscreen-input" for="fullscreen-input">
						<span class="fullscreen-input-btn">
							<transition name="fade" mode="out-in">
								<img
									v-if="!fullscreen"
									src="@/assets/img/button/fullscreen-off.svg"
									alt="Btn Fullscreen off"/>
								<img
									v-else
									src="@/assets/img/button/fullscreen-on.svg"
									alt="Btn Fullscreen on"
							/></transition>
						</span>
					</label>
				</div>
				<div
					class="menu-mobile"
					:class="{ active: menuAtivo }"
					@click="alteraMenu"
				>
					<span></span>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	import firebase from 'firebase';
	export default {
		name: 'Header',
		data() {
			return {
				fullscreen: false,
				visivel: true,
			};
		},
		computed: {
			menuAtivo() {
				return this.$store.state.menuAtivado;
			},
			nomeUsuario() {
				return this.$store.state.user.user.data.displayName.split(' ')[0];
			},
			linkRouter() {
				return this.$route.path;
			},
		},
		methods: {
			alteraMenu() {
				this.$store.commit('SET_MENU', !this.menuAtivo);
			},
			link(page) {
				this.linkRouter == page ? false : this.$router.push(page);
			},
			sair() {
				this.$store.commit('SET_ADICIONAR_LOADING');
				firebase
					.auth()
					.signOut()
					.then(() => {
						this.$store.commit('user/SET_LOGGED_IN', false);
						this.$store.commit('user/SET_USER', null);
						//
						this.$store.commit('user/SET_ATUALIZAR_LISTA', true);
						this.$store.commit('user/SET_KEY_LISTA_EXCLUIR', null);
						this.$store.commit('user/SET_LISTAS', null);
						this.$store.commit('user/SET_LISTAS_CLONE', null);
						this.$router.push('/login');
					})
					.catch((err) => {
						return this.$store.commit('SET_ALERTA', {
							ativado: true,
							titulo: 'Error',
							mensagem: err.message,
						});
					})
					.finally(() => this.$store.commit('SET_REMOVER_LOADING'));
			},
			isIOSDevice() {
				return (
					!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
				);
			},
			openFullscreen() {
				if (document.documentElement.webkitRequestFullscreen) {
					/* Safari */
					document.documentElement.webkitRequestFullscreen();
				} else if (document.documentElement.msRequestFullscreen) {
					/* IE11 */
					document.documentElement.msRequestFullscreen();
				} else if (document.documentElement.requestFullscreen) {
					document.documentElement.requestFullscreen();
				}
			},
			closeFullscreen() {
				if (document.webkitExitFullscreen) {
					/* Safari */
					document.webkitExitFullscreen();
				} else if (document.msExitFullscreen) {
					/* IE11 */
					document.msExitFullscreen();
				} else if (document.exitFullscreen) {
					document.exitFullscreen();
				}
			},
		},
		watch: {
			fullscreen(val) {
				val ? this.openFullscreen() : this.closeFullscreen();
			},
		},
		mounted() {
			if (this.isIOSDevice()) {
				this.visivel = false;
			}
		},
	};
</script>
