import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import firebase from 'firebase';
//
import Login from '@/views/login/Login.vue';
//
import Main from '@/views/_Main.vue';
import Home from '@/views/Home.vue';
import Lista from '@/views/Lista.vue';
// import CadastroLista from '@/views/CadastroLista.vue';
// import EditarLista from '@/views/EditarLista.vue';

Vue.use(VueRouter);

const routes = [
	{
		name: 'login',
		path: '/login',
		component: Login,
	},
	{
		name: 'cadastro',
		path: '/register',
		component: () =>
			import(/* webpackChunkName: "login" */ '@/views/login/Cadastro.vue'),
	},
	{
		name: 'password',
		path: '/password',
		component: () =>
			import(/* webpackChunkName: "login" */ '@/views/login/Password.vue'),
	},
	{
		path: '/',
		component: Main,
		children: [
			{
				name: 'home',
				path: '/',
				component: Home,
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: 'cadastro-lista',
				path: '/create-list',
				// component: CadastroLista,
				component: () =>
					import(
						/* webpackChunkName: "cadastroLista" */ '@/views/CadastroLista.vue'
					),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: 'editar-lista',
				path: '/edit-list/:key/:name',
				// component: EditarLista,
				component: () =>
					import(
						/* webpackChunkName: "EditarLista" */ '@/views/EditarLista.vue'
					),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: 'lista',
				path: '/list/:key/:name',
				component: Lista,
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
	{
		path: '*',
		redirect: '/',
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

function fecharElementosTela() {
	if (store.state.alerta.alertaAtivado)
		store.commit('SET_ALERTA', {
			ativado: false,
			titulo: '',
			mensagem: '',
		});
	if (store.state.menuAtivado) store.commit('SET_MENU', false);
}

router.beforeEach((to, from, next) => {
	fecharElementosTela();
	let currentUser;
	if (store.state.user.user.loggedIn) {
		currentUser = store.state.user.user.data;
	} else {
		currentUser = firebase.auth().currentUser;
		store.dispatch('user/fetchUser', currentUser);
	}
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	if (requiresAuth && !currentUser) next('/login');
	else next();
});

export default router;
