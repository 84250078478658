<template>
	<div class="main" v-if="paginaLista != null">
		<transition name="fade" mode="out-in">
			<Modal v-if="modal != ''" />
		</transition>
		<Header />
		<router-view class="main-container"></router-view>
		<LinksFooter v-if="!paginaLista" />
	</div>
</template>

<script>
	import firebase from 'firebase';
	const Modal = () => import('@/components/modal/_Modal.vue');
	import Header from '@/components/elementos/Header.vue';
	import LinksFooter from '@/components/elementos/LinksFooter.vue';
	export default {
		name: 'Main',
		components: {
			Modal,
			Header,
			LinksFooter,
		},
		data() {
			return {
				paginaLista: null,
			};
		},
		computed: {
			modal() {
				return this.$store.state.modal;
			},
			atualizarLista() {
				return this.$store.state.user.atualizarLista;
			},
		},
		methods: {
			textLastUpdated() {
				const data = new Date();
				const options = {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				};
				return `${data.toLocaleDateString(
					'en-US',
					options
				)} ${data.toLocaleTimeString('en-US')}`;
			},
			carregarListas() {
				this.$store.commit('SET_ADICIONAR_LOADING');
				const uid = firebase.auth().currentUser.uid;
				firebase
					.database()
					.ref('users')
					.child(uid)
					.get()
					.then((snapshot) => {
						if (snapshot.exists()) {
							this.$store.commit('user/SET_LISTAS', snapshot.val());
							this.$store.commit('user/SET_LISTAS_CLONE', snapshot.val());
							this.$store.commit(
								'user/SET_LAST_UPDATED',
								this.textLastUpdated()
							);
							window.localStorage.setItem(uid, JSON.stringify(snapshot.val()));
							window.localStorage.setItem(
								`${uid}-updated`,
								this.textLastUpdated()
							);
						} else {
							this.$store.commit('user/SET_LISTAS', null);
							this.$store.commit('user/SET_LISTAS_CLONE', null);
							this.$store.commit('user/SET_LAST_UPDATED', null);
						}
						this.$store.commit('user/SET_ATUALIZAR_LISTA', false);
					})
					.catch((err) => {
						return this.$store.commit('SET_ALERTA', {
							ativado: true,
							titulo: 'Error',
							mensagem: err.message,
						});
					})
					.finally(() => this.$store.commit('SET_REMOVER_LOADING'));
			},
		},
		mounted() {
			const uid = firebase.auth().currentUser.uid;
			const localStorageLista = window.localStorage.getItem(uid);
			const localStorageUpdated = window.localStorage.getItem(`${uid}-updated`);
			if (localStorageUpdated) {
				this.$store.commit('user/SET_LAST_UPDATED', localStorageUpdated);
			}
			if (localStorageLista) {
				this.$store.commit('user/SET_LISTAS', JSON.parse(localStorageLista));
				this.$store.commit(
					'user/SET_LISTAS_CLONE',
					JSON.parse(localStorageLista)
				);
				this.$store.commit('user/SET_ATUALIZAR_LISTA', false);
			} else if (this.atualizarLista) {
				this.carregarListas();
			}
			this.$route.name == 'lista'
				? (this.paginaLista = true)
				: (this.paginaLista = false);
		},
		watch: {
			modal(val) {
				return val != ''
					? document.querySelector('body').classList.add('block-scroll-modal')
					: document
							.querySelector('body')
							.classList.remove('block-scroll-modal');
			},
			atualizarLista(val) {
				val && this.carregarListas();
			},
			$route(to) {
				to.name == 'lista'
					? (this.paginaLista = true)
					: (this.paginaLista = false);
			},
		},
	};
</script>

<style lang="scss">
	.main .main-container {
		padding-top: 30px;
		box-sizing: border-box;
		min-height: calc(100vh - 250px);
	}
</style>
