<template>
	<section id="lista">
		<div class="lista-container">
			<section class="lista-container-quiz">
				<p class="quiz-palavra" v-if="iniciado && listaFiltrada">
					{{ listaFiltrada[arrayAleatoria[indice]].significado || '-' }}
				</p>
				<p class="quiz-kana nihongo" v-if="resposta">
					{{ listaFiltrada[arrayAleatoria[indice]].hiragana }}
				</p>
			</section>
			<!-- #################################################################### -->
			<!--  -->
			<!-- #################################################################### -->
			<section class="lista-container-canvas">
				<transition name="fade" mode="out-in">
					<button v-if="resposta" @click="errou" class="btn-resposta-errada">
						<img src="@/assets/img/lista/errou.svg" alt="Wrong Answer" />
					</button>
				</transition>
				<transition name="fade" mode="out-in">
					<button v-if="resposta" @click="acertou" class="btn-resposta-certa">
						<img src="@/assets/img/lista/acertou.svg" alt="Correct Answer" />
					</button>
				</transition>
				<transition name="fade" mode="out-in">
					<button
						v-if="resposta"
						class="link-jisho"
						@click="jisho(listaFiltrada[arrayAleatoria[indice]].kanji)"
					>
						<img src="@/assets/img/button/btn-jisho.svg" alt="Button Jisho" />
					</button>
					<div v-else class="placar">
						<p>
							<span class="placar-acerto">{{ kanjisFinalizados }}</span> |
							<span class="placar-erro"> {{ kanjisParaEstudar.length }}</span>
						</p>
					</div>
				</transition>
				<Canvas />
			</section>
			<!-- #################################################################### -->
			<!--  -->
			<!-- #################################################################### -->
			<section class="lista-container-resposta">
				<transition name="fade" mode="out-in">
					<div class="lista-resposta" v-if="resposta && listaFiltrada">
						<p class="nihongo">
							{{ listaFiltrada[arrayAleatoria[indice]].kanji }}
						</p>
					</div>
					<button v-else @click="responder" class="btn-lista-resposta">
						<img src="@/assets/img/lista/resposta.svg" alt="Reply" />
					</button>
				</transition>
			</section>
		</div>
	</section>
</template>

<script>
	import Canvas from '@/components/elementos/Canvas.vue';
	export default {
		name: 'Lista',
		components: {
			Canvas,
		},
		data() {
			return {
				lista: [],
				iniciado: false,
				resposta: false,
				carregandoItem: false,
				//
				arrayAleatoria: null,
				listaFiltrada: null,
				indice: 0,
			};
		},
		computed: {
			listas() {
				return this.$store.state.user.listas;
			},
			kanjisParaEstudar() {
				return this.lista.filter((item) => !item.acertou);
			},
			kanjisFinalizados() {
				return this.lista.length - this.kanjisParaEstudar.length;
			},
		},
		methods: {
			carregarLista() {
				const listas = this.$store.state.user.listas;
				const key = this.$route.params.key;
				const lista = listas[key];
				if (!lista) {
					return this.$router.push('/');
				}
				this.lista = lista.lista;
				this.iniciar();
			},
			iniciar() {
				this.iniciado = true;
				this.arrayAleatoria = this.shuffle();
				this.listaFiltrada = this.kanjisParaEstudar;
			},
			errou() {
				if (this.carregandoItem) return false;
				this.carregandoItem = true;
				this.proximoItem();
			},
			acertou() {
				if (this.carregandoItem) return false;
				this.carregandoItem = true;
				const id = this.listaFiltrada[this.arrayAleatoria[this.indice]].id;
				const posicaoArray = this.lista.findIndex((i) => i.id === id);
				this.lista[posicaoArray].acertou = true;
				this.proximoItem();
			},
			responder() {
				this.carregandoItem = false;
				this.resposta = true;
			},
			proximoItem() {
				document.querySelector('#apagar').click();
				this.resposta = false;
				if (this.kanjisParaEstudar.length == 0) {
					this.finalizado();
				} else if (this.indice == this.arrayAleatoria.length - 1) {
					this.arrayAleatoria = this.shuffle();
					this.listaFiltrada = this.kanjisParaEstudar;
				} else {
					this.indice++;
				}
			},
			finalizado() {
				this.$router.push('/');
				return this.$store.commit('SET_ALERTA', {
					ativado: true,
					titulo: 'Congratulations!',
					mensagem: 'You finished the list',
				});
			},
			shuffle() {
				let currentIndex = this.kanjisParaEstudar.length,
					randomIndex;
				let array = [];
				for (let i = 0; i < currentIndex; i++) {
					array.push(i);
				}
				while (0 !== currentIndex) {
					randomIndex = Math.floor(Math.random() * currentIndex);
					currentIndex--;
					[array[currentIndex], array[randomIndex]] = [
						array[randomIndex],
						array[currentIndex],
					];
				}
				this.indice = 0;
				return array;
			},
			jisho(kanji) {
				const fullscreen = document.querySelector('#fullscreen-input');
				if (fullscreen.checked) {
					fullscreen.click();
				}
				window.open(`https://jisho.org/search/${kanji}%23kanji`, '_blank');
			},
		},
		mounted() {
			if (this.$store.state.user.listas) {
				this.carregarLista();
			}
		},
		beforeDestroy() {
			this.lista.map((item) => (item.acertou = false));
		},
		watch: {
			listas() {
				this.carregarLista();
			},
		},
	};
</script>
