<template>
	<div class="links-footer">
		<a
			href="mailto:contact@kanjianki.com"
			rel="noopener noreferer"
			target="_blank"
			>Contact</a
		>
		<a
			href="https://www.buymeacoffee.com/marcioteruya"
			rel="noopener noreferer"
			target="_blank"
			><img src="@/assets/img/button/btn-coffee.svg" alt="Buy me a Coffee"
		/></a>
	</div>
</template>

<script>
	export default {
		name: 'LinksFooter',
	};
</script>
