import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VueGtag from 'vue-gtag';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import '@/utils';

Vue.use(
	VueGtag,
	{
		config: { id: process.env.VUE_APP_ANALYTICS },
	},
	router
);
Vue.config.productionTip = false;

let app = '';
const firebaseConfig = {
	apiKey: process.env.VUE_APP_APIKEY,
	authDomain: process.env.VUE_APP_AUTHDOMAIN,
	projectId: process.env.VUE_APP_PROJECTID,
	storageBucket: process.env.VUE_APP_STORAGEBUCKET,
	messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
	appId: process.env.VUE_APP_APPID,
};
firebase.initializeApp(firebaseConfig);
firebase.auth().onAuthStateChanged(() => {
	if (!app) {
		app = new Vue({
			router,
			store,
			render: (h) => h(App),
		}).$mount('#app');
	}
});
